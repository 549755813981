import React, {useEffect, useState} from 'react';
import {Pagination, PaginationItem, Stack, Typography} from "@mui/material";
import {TokenOperation} from "../../models/Operation";
import {apiBaseUrl} from "../../Constants";
import {ApiResponse} from "../../models/ApiResponse";
import {Token} from "../../models/Token";
import {Link, useLocation} from 'react-router-dom';
import {Timeline, timelineOppositeContentClasses} from "@mui/lab";
import HistoryOperation from "./HistoryOperation";


interface TokenTimelineProps {
    token?: Token;
}

function TokenTimeline(props: TokenTimelineProps) {
    let {token} = props;
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);

    const [operations, setOperations] = useState<TokenOperation[]>([]);
    const [totalRecords, setTotalRecords] = useState(100);
    const rowsPerPage = 10;

    useEffect(() => {
            let historyUrl;
            const offset: string = page === 1 ? '' : `?offset=${(page - 1) * rowsPerPage}`;
            historyUrl = `${apiBaseUrl}history/${token?.collection}/${token?.token}${offset}`;
            fetch(historyUrl)
                .then(response => response.json())
                .then((response: ApiResponse<TokenOperation>) => {
                    setOperations(response.data);
                    setTotalRecords(response.meta.totalRowCount + rowsPerPage);
                })
        }, [token, page]
    )

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Token History
            </Typography>

            <Stack spacing={2}>
                <Pagination page={page}
                            count={Math.floor(totalRecords / rowsPerPage)}
                            variant="outlined"
                            shape="rounded"
                            renderItem={(item) => (
                                <PaginationItem
                                    component={Link}
                                    to={`/history/${token?.collection}/${token?.token}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                    {...item}
                                />
                            )}/>
            </Stack>

            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }}
            >
                {operations.map((operation, i, arr) => {
                    const isLastItem = arr.length - 1 === i;
                    return (
                        <HistoryOperation
                            isLastItem={isLastItem}
                            operation={operation}
                            key={JSON.stringify(operation) + i}/>
                    );
                })
                }
            </Timeline>
        </div>
    );
}

export default TokenTimeline;