import React, {useEffect, useMemo, useState} from "react";

import {Box, Grid} from '@mui/material';
import {apiBaseUrl} from "../../Constants";
import {formatTez} from "../common/utils";
import {ApiResponse} from "../../models/ApiResponse";
import MaterialReactTable, {MRT_ColumnDef, MRT_ColumnFiltersState, MRT_SortingState} from "material-react-table";
import {FungibleToken} from "../../models/Token";
import {useSearchParams} from "react-router-dom";
import {ColumnFilter, Updater} from "@tanstack/table-core";


const FungiblesTable = (props: { userId?: string, current_tab?: string }) => {

    const {userId} = props;

    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState<MRT_SortingState>([{id: "num_owners", desc: true}]);
    const [totalRecords, setTotalRecords] = useState(100);

    const rowsPerPage = 10;
    const [loadedTokens, setLoadedTokens] = useState<FungibleToken[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();

    // const currentParams = Object.fromEntries([...searchParams]);

    const page = parseInt(searchParams.get('page') || '1', 10);
    const [pagination, setPagination] = useState({
        pageIndex: page != null ? page - 1 : 0,
        pageSize: rowsPerPage,
    });

    const search = searchParams.get('search');

    // if search parameter "search is not empty use it to set initial state of the table's filter (search)
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(search ? [{
        "id": "title",
        "value": search
    }] : []);

    let titleFilter = columnFilters.find((fil) => fil.id === 'title');
    let titleFilterString = titleFilter != null ? String(titleFilter.value) : "";

    const updateColumnFilters = (filters: Updater<ColumnFilter[]>) => {
        setColumnFilters(filters);

        if (titleFilterString) {
            setPagination({
                pageIndex: 0,
                pageSize: rowsPerPage,
            });
        }
    }

    useEffect(() => {
        if (titleFilterString != null && titleFilterString.length > 1) {
            searchParams.set("search", titleFilterString);
        }

        if (pagination.pageIndex > 0) {
            searchParams.set("page", String(pagination.pageIndex + 1));
        } else {
            searchParams.delete("page");
        }
        setSearchParams(searchParams);
        
    }, [titleFilterString, pagination.pageIndex]);

    useEffect(() => {
        function getOrderUrlSearchParam() {
            if (sorting.length === 0) return '';
            return '&order_by=' + (sorting[0].desc ? `-${sorting[0].id}` : sorting[0].id);
        }

        const orderBy: string = getOrderUrlSearchParam();
        const searchInTitle = titleFilterString != null && titleFilterString.length > 1 ? `&search=${titleFilterString}` : "";
        setIsLoading(true);

        const offset: string = page === 1 ? '' : `&offset=${(page - 1) * rowsPerPage}`;
        // offset=${pagination.pageIndex * rowsPerPage}
        fetch(`${apiBaseUrl}${userId}/fungible?${offset}${orderBy}${searchInTitle}`)
            .then(response => response.json())
            .then((response: ApiResponse<FungibleToken>) => {
                setTotalRecords(response.meta.totalRowCount);
                setLoadedTokens(response.data);
                setIsLoading(false);
            });
    }, [titleFilterString, sorting, userId, page]);

    const columns = useMemo<MRT_ColumnDef<FungibleToken>[]>(
        () => [
            {
                id: 'image', //id is still required when using accessorFn instead of accessorKey
                header: 'Image',
                size: 50,
                Cell: ({renderedCellValue, row}) => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <a href={`/history/${row.original.collection}/${row.original.token}`}>
                                <img
                                    alt="avatar"
                                    height={50}
                                    src={row.original.thumbnail_url}
                                    loading="lazy"
                                /></a>
                            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                            <span>{renderedCellValue}</span>
                        </Box>
                    )
                },
            },

            {
                id: 'title',
                accessorFn: (row) => row.title ? row.title : '',
                enableClickToCopy: false,
                header: 'Title',
                enableColumnFilter: true,
                sortDescFirst: true,
                enableMultiSort: false,
                size: 100,
            },
            {
                id: 'min_price',
                accessorFn: (row) => formatTez(row.min_price!),
                enableClickToCopy: false,
                header: 'Min (tez)',
                enableColumnFilter: false,
                sortDescFirst: true,
                enableMultiSort: false,
                size: 50,
            },
            {
                id: 'max_price',
                accessorFn: (row) => formatTez(row.max_price!),
                enableClickToCopy: false,
                header: 'Max (tez)',
                enableColumnFilter: false,
                sortDescFirst: true,
                enableMultiSort: false,
                size: 50,
            },
            {
                accessorFn: (row) => row.num_owners,
                id: 'num_owners', //id is still required when using accessorFn instead of accessorKey
                header: 'Owners',
                size: 50,
                sortDescFirst: true,
                enableColumnFilter: false,
                enableMultiSort: false,
                enableSorting: true,
                Cell: ({renderedCellValue, row}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {row.original.num_owners}
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        {/*<span>{renderedCellValue}</span>*/}
                    </Box>
                ),
            },

        ],
        [],
    );

    function onPaginationChange(pgntn: any) {
        setPagination(pgntn);
    }

    return (<Grid container spacing={2} alignItems="stretch">

            <MaterialReactTable
                columns={columns}
                data={loadedTokens}
                positionToolbarAlertBanner="bottom"
                enableColumnActions={false}
                enableTopToolbar={false}

                manualPagination
                rowCount={totalRecords}

                muiTablePaginationProps={{
                    rowsPerPageOptions: [rowsPerPage],
                    showFirstButton: pagination.pageIndex > 0,
                    showLastButton: totalRecords / rowsPerPage > 1,
                    page: pagination.pageIndex
                }}
                onPaginationChange={onPaginationChange}


                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}

                manualFiltering
                onColumnFiltersChange={updateColumnFilters}
                enableDensityToggle={false}
                initialState={{density: 'compact'}}
                state={{
                    pagination,
                    isLoading,
                    showProgressBars: isLoading,
                    showColumnFilters: true,
                    sorting,
                    columnFilters
                }}

                manualSorting
                onSortingChange={setSorting}

                renderDetailPanel={({row}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'left',
                            gap: '1rem',
                        }}
                    >
                        <img
                            alt="avatar"
                            height={200}
                            src={row.original.thumbnail_url}
                            loading="lazy"
                        />
                    </Box>
                )}
            />
        </Grid>

    );
};

export default FungiblesTable;


