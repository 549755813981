import React, {useEffect, useMemo, useState} from "react";

import {formatTez, getDomainProfileOrWallet} from "../common/utils";

import {Box, Button, Grid, Typography} from '@mui/material';
import {apiBaseUrl} from "../../Constants";
import {Collector} from "../../models/Collector";
import {ApiResponse} from "../../models/ApiResponse";
import MaterialReactTable, {MRT_ColumnDef, MRT_ColumnFiltersState, MRT_SortingState} from "material-react-table";
import Spacer from "../common/components/Spacer";
import {PROFILE_PATH} from "../common/Constants";
import {useSearchParams} from "react-router-dom";
import {ColumnFilter, Updater} from "@tanstack/table-core";

const CollectorsTable = (props: { userId?: string, current_tab: string }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(100);
    const [sorting, setSorting] = useState<MRT_SortingState>([{id: "total_amount", desc: true}]);
    const [searchParams, setSearchParams] = useSearchParams();

    const rowsPerPage = 10;
    const [loadedUsers, setLoadedUsers] = useState<Collector[]>([]);

    const page = parseInt(searchParams.get('page') || '1', 10);

    const [pagination, setPagination] = useState({
        pageIndex: page != null ? page - 1 : 0,
        pageSize: rowsPerPage,
    });

    const search = searchParams.get('search');
    // if search parameter "search is not empty use it to set initia state of the table's filter (search)
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(search ? [{
        "id": "collector",
        "value": search
    }] : []);

    let collectorFilter = columnFilters.find((fil) => fil.id === 'collector');
    let collectorFilterString = collectorFilter != null ? String(collectorFilter.value) : "";

    const updateColumnFilters = (filters: Updater<ColumnFilter[]>) => {
        setColumnFilters(filters);

        if (collectorFilter) {
            setPagination({
                pageIndex: 0,
                pageSize: rowsPerPage,
            });
        }
    }


    useEffect(() => {

        if (collectorFilterString != null && collectorFilterString.length > 1) {
            searchParams.set("search", collectorFilterString);
        }

        if (pagination.pageIndex > 0) {
            searchParams.set("page", String(pagination.pageIndex + 1));
        } else {
            searchParams.delete("page");
        }

        setSearchParams(searchParams);

    }, [pagination.pageIndex, collectorFilterString]);


    useEffect(() => {
        function getOrderUrlSearchParam() {
            if (sorting.length === 0) return '';
            return '&order_by=' + (sorting[0].desc ? `-${sorting[0].id}` : sorting[0].id);
        }

        const orderBy: string = getOrderUrlSearchParam();
        const searchCollector = collectorFilterString != null && collectorFilterString.length > 1 ? `&search=${collectorFilterString}` : "";
        setIsLoading(true);

        fetch(`${apiBaseUrl}${props.userId}/collectors?offset=${pagination.pageIndex * rowsPerPage}${orderBy}${searchCollector}`)
            .then(response => response.json())
            .then((response: ApiResponse<Collector>) => {
                setTotalRecords(response.meta.totalRowCount);
                setLoadedUsers(response.data);
                setIsLoading(false);
            });
    }, [pagination.pageIndex, collectorFilterString, sorting, props.userId]);

    const columns = useMemo<MRT_ColumnDef<Collector>[]>(
        () => [
            {
                id: 'collector', //id is still required when using accessorFn instead of accessorKey
                acsessorKey: 'collector', //id is still required when using accessorFn instead of accessorKey
                header: 'Collector',
                size: 100,
                Cell: ({renderedCellValue, row}) => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <a href={`/${PROFILE_PATH}/${row.original.wallet}`}>
                                <img
                                    alt="avatar"
                                    height={50}
                                    src={row.original.thumbnail_url}
                                    loading="lazy"
                                />
                            </a>
                            &nbsp;
                            <a href={`/${PROFILE_PATH}/${row.original.wallet}`}>{getDomainProfileOrWallet(row.original)}</a>
                            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                            {/*<span>{renderedCellValue}</span>*/}
                        </Box>
                    )
                },
                accessorFn: (row) => <a href={`/${PROFILE_PATH}/${row.wallet}`}>{getDomainProfileOrWallet(row)}</a>,
                enableColumnFilter: true,
            },

            {
                id: 'quantity',
                accessorKey: 'quantity',
                enableClickToCopy: false,
                header: 'Number of tokens',
                enableColumnFilter: false,
                sortDescFirst: true,
                enableMultiSort: false,
                size: 50,
            },
            {
                accessorFn: (row) => row.total_amount,
                id: 'total_amount', //id is still required when using accessorFn instead of accessorKey
                header: 'Value (Tez)',
                size: 50,
                sortDescFirst: true,
                enableColumnFilter: false,
                enableMultiSort: false,
                enableSorting: true,
                Cell: ({row}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {formatTez(row.original.total_amount)}
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        {/*<span>{renderedCellValue}</span>*/}
                    </Box>
                ),
            },

        ],
        [],
    );

    return (<Grid container spacing={2} alignItems="stretch">

            <MaterialReactTable
                columns={columns}
                data={loadedUsers}
                positionToolbarAlertBanner="bottom"
                enableColumnActions={false}
                enableTopToolbar={false}

                manualPagination
                rowCount={totalRecords}

                muiTablePaginationProps={{
                    rowsPerPageOptions: [rowsPerPage],
                    showFirstButton: pagination.pageIndex > 0,
                    showLastButton: totalRecords / rowsPerPage > 1,
                    page: pagination.pageIndex
                }}
                onPaginationChange={setPagination}


                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}

                manualFiltering
                onColumnFiltersChange={updateColumnFilters}
                enableDensityToggle={false}
                initialState={{density: 'compact'}}
                state={{
                    pagination, isLoading, showProgressBars: isLoading, showColumnFilters: true, sorting, columnFilters
                }}

                manualSorting
                onSortingChange={setSorting}

                renderDetailPanel={({row}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'left',
                            gap: '1rem',
                        }}
                    >
                        <img
                            alt="avatar"
                            height={200}
                            src={row.original.thumbnail_url}
                            loading="lazy"
                        />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: "stretch",
                            gap: '1rem',
                            alignItems: "center",
                            flexGrow: 2,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignSelf: "stretch",
                            }}>
                                <Typography
                                    variant="h4">{row.original.tezos_profile_name || row.original.tezos_domain}</Typography>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexGrow: 2,
                                    alignItems: "stretch",
                                    alignContent: "flex-end",
                                }}><Spacer/>
                                    <Button size="small"><a
                                        href={`https://objkt.com/profile/${row.original.wallet}`}>Objkt.com</a></Button>
                                </Box>
                            </Box>
                            <a href={`https://twitter.com/${row.original.twitter_handle}`}>@{row.original.twitter_handle}</a>
                            <Button variant="outlined" style={{color: 'white'}}><a
                                href={`/${PROFILE_PATH}/${row.original.wallet}`}>Collector's
                                profile</a></Button>

                        </Box>
                    </Box>
                )}
            />
        </Grid>

    );
};


export default CollectorsTable;