import * as React from 'react';
import {styled} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Grid} from "@mui/material";
import {formatTez} from "../../common/utils";
import {DESCRIPTION_PREVIEW_LENGTH} from "../../common/Constants";
import {Nft} from "../../../models/Token";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function NFTCard(props: { nft: Nft }) {
    const {nft} = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Grid item xs={12} md={3} sx={{minWidth: '300px'}}>
            <Card>
                <CardHeader
                    avatar={
                        // <Avatar sx={{bgcolor: red[500]}} aria-label="recipe">
                        <></>
                        // </Avatar>
                    }
                    // action={
                    //     <IconButton aria-label="settings">
                    //         <MoreVertIcon/>
                    //     </IconButton>
                    // }
                    title={`Value: ${formatTez(nft.last_price)} Tez`}
                    subheader={new Date(nft.created).toLocaleDateString()}
                />
                <a href={`/history/${nft.collection}/${nft.token}`}>
                <CardMedia
                    component="img"
                    height="194"
                    image={nft.thumbnail_url}
                    alt={nft.token}
                />
                </a>
                <CardContent>
                    <Typography variant="h6" color="text.secondary" style={{height: 70}}>
                        {nft.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {nft.description != null && nft.description.slice(0, DESCRIPTION_PREVIEW_LENGTH) + '...'}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    {/*<a href={`/history/${nft.collection}/${nft.token}`}>*/}
                    {/*    <StorefrontIcon color={'action'}/>*/}
                    {/*</a>*/}

                    {nft.description != null && nft.description.length > DESCRIPTION_PREVIEW_LENGTH &&
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>}
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph>
                            {nft.description}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
}