import {
    Bar, Brush,
    CartesianGrid,
    ComposedChart,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {Avatar, Chip, LinearProgress, Typography, useMediaQuery} from "@mui/material";
import {apiBaseUrl} from "../../Constants";
import {formatTez} from "../common/utils";
import {blue, deepPurple, green, red} from '@mui/material/colors';
import dayjs, {Dayjs} from "dayjs";

const RETRY_DELAY_MS = 3000;
type Datapoint = { date: string, value: number, sales: number, transfers: number };

const SVGCircle = (props: { color: string, cx: number, cy: number }) => {
    return <svg
        x={props.cx - 4}
        y={props.cy - 4}
        width={8}
        height={8}
        viewBox="0 0 1024 1024"
    >
        <circle
            cx="50%"
            cy="50%"
            r="40%"
            stroke={props.color}
            strokeWidth="20%"
            fill={'none'}
        />
    </svg>
}

interface ArtistValueChartProps {
    wallet?: string,
    proportionDesktop: number,
    selectedDay?: Dayjs,
    onSelectedDayChange?: (newDay: Dayjs | null) => void;
}

function ArtistsValueChart(props: ArtistValueChartProps) {
    const {wallet, proportionDesktop} = props;
    const [historyData, setHistoryData] = useState<Datapoint[]>([]);
    const [shouldRetry, setShouldRetry] = useState(true);
    const [retryCount, setRetryCount] = useState(0);

    const theme = useTheme(); //this is from mui
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const today = (new Date()).toISOString().slice(0, 10);
        const yearAgo = (new Date((new Date()).setFullYear((new Date()).getFullYear()-1))).toISOString().slice(0, 10);

        if (wallet != null && shouldRetry && retryCount < 10) {
            // https://api.artcrowd.org/timeseries/tz1eq8KkmRaGq9Y95YT4M4N4rQYtG3qH2ZDx/2021-11-18/2022-01-31
            fetch(`${apiBaseUrl}timeseries/${wallet}/${yearAgo}/${today}`).then(async response => {
                if (response.ok && response.status === 200) {
                    response.json()
                        .then(history => {
                            const formattedHistory = history.map((point: Datapoint, index: number, arr: Datapoint[]) => {
                                const prevDatapoint = arr[index - 1];
                                if (prevDatapoint?.value === point.value) {
                                    return {date: point.date, value: null};
                                }
                                if (prevDatapoint?.value == null) {
                                    return point;
                                }
                                return {...point, diff: point.value - prevDatapoint.value};
                            });
                            setHistoryData(formattedHistory);
                            setShouldRetry(false);
                        })
                } else if (response.status === 202) {
                    setTimeout(() => setRetryCount(retryCount + 1), RETRY_DELAY_MS)
                } else {
                    setShouldRetry(false)
                }
            });
        }
    }, [wallet, shouldRetry, retryCount])

    const renderCustomYTick = (payload: any) => {
        const {x, y, width} = payload;
        return <text x={x - width / 2} y={y + 10} fill="#666" textAnchor="middle"
                     dy={-6}>{`${formatTez(payload.payload.value)}`}</text>;
    };

    function CustomTooltip(pl: any) {
        const {active, label, payload} = pl;

        if (active && payload?.[0]?.payload != null) {
            return (<>
                <Typography sx={{paddingLeft: 1}}> {`${label}`}</Typography>
                <Chip label={`${formatTez(payload[0].payload.value)}`} color="primary" variant="outlined"
                      avatar={<Avatar> ꜩ </Avatar>}/>
                {payload[0].payload.diff &&
                <Typography sx={{paddingLeft: 1, color: payload[0].payload.diff > 0 ? green.A700 : red.A200}}>
                    ( {payload[0].payload.diff > 0 ? '+' : '-'} {`${formatTez(payload[0].payload.diff)} Tez`} )
                </Typography>}
                {payload[0].payload.sales && payload[0].payload.sales > 0 && <Typography sx={{paddingLeft: 1}}>
                    Sales: {payload[0].payload.sales}
                </Typography>}
                {payload[0].payload.transfers && payload[0].payload.transfers > 0 && <Typography sx={{paddingLeft: 1}}>
                    Transfers: {payload[0].payload.transfers}
                </Typography>}
            </>);
        }

        return null;
    }

    const CustomizedDot = (params: any) => {
        const {cx, cy, payload} = params;

        if (props.selectedDay != null && props.selectedDay.isSame(dayjs(params.payload.date), 'date')) {
            return (<SVGCircle color={deepPurple["900"]} cx={cx} cy={cy}/>);
        } else if (payload && payload.diff < 0) {
            return (<SVGCircle color={red.A200} cx={cx} cy={cy}/>);
        } else if (payload && payload.diff > 0) {
            return (<SVGCircle color={green.A700} cx={cx} cy={cy}/>);
        } else return null;
    };

    if (shouldRetry) return (<div style={{
        position: 'relative',
        width: '100%',
        paddingBottom: '250px'
    }}>
        {/*this is a hack to make it responsive*/}
        <div
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,

            }}
        ><LinearProgress/>
        </div>
    </div>)

    function onChartClicked(data:any) {
        if (props.onSelectedDayChange && data) {
            props.onSelectedDayChange(dayjs(data.activePayload[0].payload.date))
        }
    }

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            paddingBottom: '250px'
        }}>
            {/*this is a hack to make it responsive*/}
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,

                }}
            >
                <ResponsiveContainer width="99%" aspect={isMobile ? 1.5 : proportionDesktop}>
                    {/*from https://recharts.org/en-US/examples/SimpleLineChart*/}

                    <ComposedChart
                        data={historyData}
                        margin={{
                            top: 5,
                            left: 20,
                            bottom: 5,
                        }}
                        style={{paddingLeft: -16}}
                        onClick={(data)=> onChartClicked(data) }
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="date"/>
                        <Brush dataKey="date" height={30} stroke={blue["700"]} />

                        {/*here's how domain works: https://stackoverflow.com/questions/50078787/recharts-set-y-axis-range*/}
                        <YAxis yAxisId="left"
                               tick={renderCustomYTick}
                               domain={([dataMin, dataMax]) => {
                                   return [Math.floor(dataMin - dataMax / 10), Math.ceil(dataMax + dataMax / 10)];
                               }}/>
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            domain={([_dataMin, dataMax]) => {
                                return [0, Math.ceil(dataMax + dataMax / 10)];
                            }}/>

                        <Tooltip content={<CustomTooltip/>}/>

                        <Line yAxisId="left"
                              type="stepAfter"
                              dataKey="value"
                              stroke="#8884d8"
                              activeDot={{r: 8}}
                              isAnimationActive={false}
                              connectNulls={true}
                              dot={<CustomizedDot/>}
                        />

                        {props.selectedDay != null && <ReferenceLine yAxisId="left" x={props.selectedDay.format('YYYY-MM-DD')} stroke={red.A200} label={`${props.selectedDay!.format('YYYY-MM-DD')}`}/>}

                        <Bar yAxisId="right"
                             dataKey="sales"
                             barSize={100}
                             fill={blue.A200}
                            />

                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>);
}

export default ArtistsValueChart;